import React, { useMemo } from 'react'
import { useField, useForm } from 'react-final-form-hooks'
import { required } from '../../helpers/hooks/validators'
import { toast } from 'react-toastify'
import { strategyService } from '../../services'
import _ from 'lodash'
import { useRequest } from '../../services/api'
import obj from './helpers'

function EditStrategyForm({ revalidate, data, closeModal }) {
  const { data: indicatorsData } = useRequest('/admin/options-auto-signals/indicators')
  const { data: strategy = {} } = useRequest(data.id ? `/admin/options-auto-signals/strategies/${data.id}` : null)
  const { indicators = [] } = indicatorsData || {}

  const inds = useMemo(() => {
    if (strategy.groups?.length) {
      const groupedConditions = []

      for (const group of strategy.groups) {
        if (group.conditionType === 'entry') {
          groupedConditions.push({
            name: 'GROUP',
            config: {
              groupType: group.type,
            },
            indicator: {
              name: 'GROUP',
              groupType: {
                type: 'enum',
                values: ['all', 'any']
              }
            },
          })

          for (const condition of group.indicators) {
            const raw = indicators.find(rawInd => rawInd.name === condition.name)

            groupedConditions.push({
              ...condition,
              indicator: raw,
            })
          }
        }
      }

      return groupedConditions
    }
    return (strategy.indicators || []).map(i => {
      const raw = indicators.find(rawInd => rawInd.name === i.name)

      return {
        ...i,
        indicator: raw,
      }
    })
  }, [strategy.indicators, strategy.groups, indicators])

  const ptInds = useMemo(() => {
    if (strategy.groups?.length) {
      const groupedConditions = []

      for (const group of strategy.groups) {
        if (group.conditionType === 'price-target') {

          groupedConditions.push({
            name: 'GROUP',
            config: {
              groupType: group.type,
            },
            indicator: {
              name: 'GROUP',
              groupType: {
                type: 'enum',
                values: ['all', 'any']
              }
            },
          })
          for (const condition of group.indicators) {
            const raw = indicators.find(rawInd => rawInd.name === condition.name)

            groupedConditions.push({
              ...condition,
              indicator: raw,
            })
          }
        }
      }

      return groupedConditions
    }
    return (strategy.priceTargetConditions || []).map(i => {
      const raw = indicators.find(rawInd => rawInd.name === i.name)

      return {
        ...i,
        indicator: raw,
      }
    })
  }, [strategy.priceTargetConditions, strategy.groups, indicators])

  const slInds = useMemo(() => {
    if (strategy.groups?.length) {
      const groupedConditions = []

      for (const group of strategy.groups) {
        if (group.conditionType === 'stop-loss') {
          groupedConditions.push({
            name: 'GROUP',
            config: {
              groupType: group.type,
            },
            indicator: {
              name: 'GROUP',
              groupType: {
                type: 'enum',
                values: ['all', 'any']
              }
            },
          })

          for (const condition of group.indicators) {
            const raw = indicators.find(rawInd => rawInd.name === condition.name)

            groupedConditions.push({
              ...condition,
              indicator: raw,
            })
          }
        }
      }

      return groupedConditions
    }
    return (strategy.stopLossConditions || []).map(i => {
      const raw = indicators.find(rawInd => rawInd.name === i.name)

      return {
        ...i,
        indicator: raw,
      }
    })
  }, [strategy.stopLossConditions, strategy.groups, indicators])

  const { form, handleSubmit, pristine, submitting } = useForm({
    async onSubmit(values, formAPI) {
      console.log('values.indicators', values.indicators)
      const mappedIndicators = obj.mapIndicators(values.indicators || [], 'entry')
      const priceTargetConditions = obj.mapIndicators(values.priceTargetConditions || [], 'price-target')
      const stopLossConditions = obj.mapIndicators(values.stopLossConditions || [], 'stop-loss')
      console.log('mappedIndicators', mappedIndicators)
      // delete values.indicators
      // delete values.priceTargetConditions
      // delete values.stopLossConditions

      await strategyService.edit(
        {
          ...values,
          indicators: mappedIndicators,
          priceTargetConditions,
          stopLossConditions
        }, data.id)
      toast.success('Strategy successfully edited')
      await revalidate()
      closeModal()
    },
    initialValues: {
      active: data.active,
      env: data.env,
      accountSize: data.accountSize,
      optionPriceFrom: data.optionPriceFrom,
      optionPriceTo: data.optionPriceTo,
      indicators: inds,
      priceTargetConditions: ptInds,
      stopLossConditions: slInds,
    }
  })

  const fieldEnv = useField('env', form, required)
  const fieldActive = useField('active', form, required)
  const fieldAccountSize = useField('accountSize', form, required)
  const fieldOptionPriceFrom = useField('optionPriceFrom', form, required)
  const fieldOptionPriceTo = useField('optionPriceTo', form, required)

  const fieldIndicators = useField('indicators', form, required)
  const fieldPriceTargetConditions = useField('priceTargetConditions', form)
  const fieldStopLossConditions = useField('stopLossConditions', form)

  return (
    <form className="bg-white rounded p-3" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <label>Environment</label>
            <select {...fieldEnv.input} className="form-control rounded">
              <option value="">Select...</option>
              <option value="test">Test</option>
              <option value="live">Live</option>
              <option value="sandbox">Sandbox</option>
            </select>
            {fieldEnv.meta.touched && fieldEnv.meta.error && (
              <span className="text-danger text-sm">{fieldEnv.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Active</label>
            <select {...fieldActive.input} className="form-control rounded">
              <option value="">Select...</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
            {fieldActive.meta.touched && fieldActive.meta.error && (
              <span className="text-danger text-sm">{fieldActive.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Option Price From</label>
            <input {...fieldOptionPriceFrom.input} placeholder="0.51" className="form-control rounded" />
            {fieldOptionPriceFrom.meta.touched && fieldOptionPriceFrom.meta.error && (
              <span className="text-danger text-sm">{fieldOptionPriceFrom.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Option Price To</label>
            <input {...fieldOptionPriceTo.input} placeholder="0.70" className="form-control rounded" />
            {fieldOptionPriceTo.meta.touched && fieldOptionPriceTo.meta.error && (
              <span className="text-danger text-sm">{fieldOptionPriceTo.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Account Size</label>
            <input {...fieldAccountSize.input} placeholder="100" className="form-control rounded" />
            {fieldAccountSize.meta.touched && fieldAccountSize.meta.error && (
              <span className="text-danger text-sm">{fieldAccountSize.meta.error}</span>
            )}
          </div>
        </div>

        <div className="col-lg-12">
          <div className="form-group row">
            <div className="col-md-12">
              <label>Entry Conditions</label>
            </div>
            {_.map(fieldIndicators.input.value, (item, idx) => (
              <div className="col-md-12" key={idx}>
                <div className="row mt-2">
                  <div className="col-md-2" key={item?.name + idx}>
                    <select
                      onChange={(e) => {
                        const config = {}
                        const ind = indicators.find(i => i.name === e.target.value) || {}

                        for (const key of Object.keys(ind)) {
                          config[key] = ''
                        }

                        config.type = ind.type

                        const { value = [], onChange } = fieldIndicators.input
                        onChange(value.map((c, index) => (index === idx ? {
                          ...config,
                          config,
                          name: e.target.value,
                          indicator: ind,
                        } : c)))
                      }}
                      className="form-control rounded"
                      defaultValue={item?.name}
                    >
                      <option value="">Select...</option>
                      {_.map(indicators, (item, idx) => (
                        <option key={idx} value={item?.name}>{item?.name}</option>
                      ))}
                    </select>
                    {fieldIndicators.meta.touched && fieldIndicators.meta.error && (
                      <span className="text-danger text-sm">{fieldIndicators.meta.error}</span>
                    )}
                  </div>
                  {
                    Object.keys(item.indicator || {}).map(key => {
                      if (key === 'name' || key === 'type') {
                        return null
                      }
                      if (item.indicator[key].type === 'enum') {
                        return (
                          <div className="col-md-2" key={key + idx}>
                            <select
                              className="form-control rounded mb-2"
                              value={item.config[key]}
                              onChange={(e) => {
                                const { value = [], onChange } = fieldIndicators.input
                                console.log('value', value)

                                onChange(value.map((c, index) => (index === idx ? {
                                  ...c,
                                  config: {
                                    ...c.config || {},
                                    [key]: e.target.value,
                                  },
                                  [key]: e.target.value
                                } : c)))
                              }}
                            >
                              <option>Select {key}...</option>
                              {
                                item.indicator[key].values.map(c => {
                                  return (
                                    <option key={c} value={c}>{c}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        )
                      }

                      return (
                        <div className="col-md-2" key={key + idx}>
                          <input
                            type={item.indicator[key].type}
                            placeholder={item.indicator[key].placeholder || key}
                            className="form-control rounded mb-2"
                            value={item.config[key]}
                            onChange={(e) => {
                              const { value = [], onChange } = fieldIndicators.input
                              console.log('value1', value)
                              onChange(value.map((c, index) => (index === idx ? {
                                ...c,
                                config: {
                                  ...c.config || {},
                                  [key]: e.target.value,
                                },
                                [key]: e.target.value
                              } : c)))
                            }}
                          />
                        </div>
                      )
                    })}
                  <div className="col-md-12 mb-3">
                    <button
                      type="button"
                      className="btn-danger btn-square mt-1"
                      onClick={() =>
                        fieldIndicators.input.onChange(
                          fieldIndicators.input.value.filter((c, index) => index !== idx),
                        )
                      }
                    >
                      <i className="fa fa-trash" />
                    </button>
                  </div>
                </div>
              </div>
            ))}

            <div className="col-md-12">
              <button
                type="button"
                className="btn btn-primary px-2 py-0 btn-sm"
                onClick={() => {
                  fieldIndicators.input.onChange([...fieldIndicators.input.value, { indicator: {} }])
                }}
              >
                + Add more
              </button>
            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="form-group row">
            <div className="col-md-12">
              <label>Price Target Conditions</label>
            </div>
            {_.map(fieldPriceTargetConditions.input.value, (item, idx) => (
              <div className="col-md-12" key={idx}>
                <div className="row mt-2">
                  <div className="col-md-2" key={item?.name + idx}>
                    <select
                      onChange={(e) => {
                        const config = {}
                        const ind = indicators.find(i => i.name === e.target.value) || {}

                        for (const key of Object.keys(ind)) {
                          config[key] = ''
                        }

                        config.type = ind.type

                        const { value = [], onChange } = fieldPriceTargetConditions.input
                        onChange(value.map((c, index) => (index === idx ? {
                          ...config,
                          config,
                          name: e.target.value,
                          indicator: ind,
                        } : c)))
                      }}
                      className="form-control rounded"
                      defaultValue={item?.name}
                    >
                      <option value="">Select...</option>
                      {_.map(indicators, (item, idx) => (
                        <option key={idx} value={item?.name}>{item?.name}</option>
                      ))}
                    </select>
                    {fieldPriceTargetConditions.meta.touched && fieldPriceTargetConditions.meta.error && (
                      <span className="text-danger text-sm">{fieldPriceTargetConditions.meta.error}</span>
                    )}
                  </div>
                  {
                    Object.keys(item.indicator || {}).map(key => {
                      if (key === 'name' || key === 'type') {
                        return null
                      }
                      if (item.indicator[key].type === 'enum') {
                        return (
                          <div className="col-md-2" key={key + idx}>
                            <select
                              className="form-control rounded mb-2"
                              value={item.config[key]}
                              onChange={(e) => {
                                const { value = [], onChange } = fieldPriceTargetConditions.input
                                console.log('value', value)

                                onChange(value.map((c, index) => (index === idx ? {
                                  ...c,
                                  config: {
                                    ...c.config || {},
                                    [key]: e.target.value,
                                  },
                                  [key]: e.target.value
                                } : c)))
                              }}
                            >
                              <option>Select {key}...</option>
                              {
                                item.indicator[key].values.map(c => {
                                  return (
                                    <option key={c} value={c}>{c}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        )
                      }

                      return (
                        <div className="col-md-2" key={key + idx}>
                          <input
                            type={item.indicator[key].type}
                            placeholder={item.indicator[key].placeholder || key}
                            className="form-control rounded mb-2"
                            value={item.config[key]}
                            onChange={(e) => {
                              const { value = [], onChange } = fieldPriceTargetConditions.input
                              console.log('value1', value)
                              onChange(value.map((c, index) => (index === idx ? {
                                ...c,
                                config: {
                                  ...c.config || {},
                                  [key]: e.target.value,
                                },
                                [key]: e.target.value
                              } : c)))
                            }}
                          />
                        </div>
                      )
                    })}
                  <div className="col-md-12 mb-3">
                    <button
                      type="button"
                      className="btn-danger btn-square mt-1"
                      onClick={() =>
                        fieldPriceTargetConditions.input.onChange(
                          fieldPriceTargetConditions.input.value.filter((c, index) => index !== idx),
                        )
                      }
                    >
                      <i className="fa fa-trash" />
                    </button>
                  </div>
                </div>
              </div>
            ))}

            <div className="col-md-12">
              <button
                type="button"
                className="btn btn-primary px-2 py-0 btn-sm"
                onClick={() => {
                  fieldPriceTargetConditions.input.onChange([...fieldPriceTargetConditions.input.value, { indicator: {} }])
                }}
              >
                + Add more
              </button>
            </div>
          </div>
        </div>


        <div className="col-lg-12">
          <div className="form-group row">
            <div className="col-md-12">
              <label>Stop Loss Conditions</label>
            </div>
            {_.map(fieldStopLossConditions.input.value, (item, idx) => (
              <div className="col-md-12" key={idx}>
                <div className="row mt-2">
                  <div className="col-md-2" key={item?.name + idx}>
                    <select
                      onChange={(e) => {
                        const config = {}
                        const ind = indicators.find(i => i.name === e.target.value) || {}

                        for (const key of Object.keys(ind)) {
                          config[key] = ''
                        }

                        config.type = ind.type

                        const { value = [], onChange } = fieldStopLossConditions.input
                        onChange(value.map((c, index) => (index === idx ? {
                          ...config,
                          config,
                          name: e.target.value,
                          indicator: ind,
                        } : c)))
                      }}
                      className="form-control rounded"
                      defaultValue={item?.name}
                    >
                      <option value="">Select...</option>
                      {_.map(indicators, (item, idx) => (
                        <option key={idx} value={item?.name}>{item?.name}</option>
                      ))}
                    </select>
                    {fieldStopLossConditions.meta.touched && fieldStopLossConditions.meta.error && (
                      <span className="text-danger text-sm">{fieldStopLossConditions.meta.error}</span>
                    )}
                  </div>
                  {
                    Object.keys(item.indicator || {}).map(key => {
                      if (key === 'name' || key === 'type') {
                        return null
                      }
                      if (item.indicator[key].type === 'enum') {
                        return (
                          <div className="col-md-2" key={key + idx}>
                            <select
                              className="form-control rounded mb-2"
                              value={item.config[key]}
                              onChange={(e) => {
                                const { value = [], onChange } = fieldStopLossConditions.input
                                console.log('value', value)

                                onChange(value.map((c, index) => (index === idx ? {
                                  ...c,
                                  config: {
                                    ...c.config || {},
                                    [key]: e.target.value,
                                  },
                                  [key]: e.target.value
                                } : c)))
                              }}
                            >
                              <option>Select {key}...</option>
                              {
                                item.indicator[key].values.map(c => {
                                  return (
                                    <option key={c} value={c}>{c}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        )
                      }

                      return (
                        <div className="col-md-2" key={key + idx}>
                          <input
                            type={item.indicator[key].type}
                            placeholder={item.indicator[key].placeholder || key}
                            className="form-control rounded mb-2"
                            value={item.config[key]}
                            onChange={(e) => {
                              const { value = [], onChange } = fieldStopLossConditions.input
                              console.log('value1', value)
                              onChange(value.map((c, index) => (index === idx ? {
                                ...c,
                                config: {
                                  ...c.config || {},
                                  [key]: e.target.value,
                                },
                                [key]: e.target.value
                              } : c)))
                            }}
                          />
                        </div>
                      )
                    })}
                  <div className="col-md-12 mb-3">
                    <button
                      type="button"
                      className="btn-danger btn-square mt-1"
                      onClick={() =>
                        fieldStopLossConditions.input.onChange(
                          fieldStopLossConditions.input.value.filter((c, index) => index !== idx),
                        )
                      }
                    >
                      <i className="fa fa-trash" />
                    </button>
                  </div>
                </div>
              </div>
            ))}

            <div className="col-md-12">
              <button
                type="button"
                className="btn btn-primary px-2 py-0 btn-sm"
                onClick={() => {
                  fieldStopLossConditions.input.onChange([...fieldStopLossConditions.input.value, { indicator: {} }])
                }}
              >
                + Add more
              </button>
            </div>
          </div>
        </div>

        <div className="col-md-12 mt-4">
          <div className="form-group">
            <button type="submit" className="btn btn-primary" disabled={pristine || submitting}>
              Edit
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default EditStrategyForm
