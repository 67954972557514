import _ from 'lodash'

export default {
  SYMBOLS: () => {
    return _.sortBy([...new Set([
      'C',
      'DAL',
      'TLRY',
      'IWM',
      'SPY',
      'QQQ',
      'AMD',
      'NVDA',
      'NFLX',
      'META',
      'AMZN',
      'GME',
      'AMC',
      'PLTR',
      'AAPL',
      'MU',
      'COIN',
      'OXY',
      'XOM',
      'BABA',
      'NIO',
      'TGT',
      'WMT',
      'BA',
      'MSFT',
      'SOFI',
      'DKNG',
      'BAC',
      'TSLA',
      'GOOGL',
      'ABNB',
      'AFRM',
      'AI',
      'CS',
      'INTC',
      'JPM',
      'KRE',
      'SQ',
      'TLT',
      'WFC',
      'ARKK',
      'DIS',
      'EEM',
      'GLD',
      'PATH',
      'PYPL',
      'RBLX',
      'ROKU',
      'SCHW',
      'SHOP',
      'SLV',
      'SNAP',
      'SNOW',
      'AAL',
      'AFRM',
      'AI',
      'ARKK',
      'BB',
      'BSX',
      'C',
      'CCJ',
      'CCL',
      'CHPT',
      'CLF',
      'CMCSA',
      'CSCO',
      'CVNA',
      'DIS',
      'DVN',
      'EEM',
      'EFA',
      'ENVX',
      'EOSE',
      'ET',
      'EWZ',
      'F',
      'FCX',
      'FTCH',
      'FXI',
      'GDX',
      'GLD',
      'GM',
      'GOOG',
      'HYG',
      'INTC',
      'IWM',
      'JD',
      'JNJ',
      'JPM',
      'KRE',
      'KVUE',
      'KWEB',
      'LCID',
      'MARA',
      'MRVL',
      'NKLA',
      'ON',
      'ORCL',
      'PANW',
      'PBR',
      'PDD',
      'PFE',
      'PLUG',
      'PYPL',
      'RIOT',
      'RIVN',
      'SA',
      'SE',
      'SHOP',
      'SLV',
      'SNAP',
      'SNOW',
      'SOXL',
      'SOXS',
      'SQQQ',
      'SVXY',
      'TLT',
      'TQQQ',
      'UBER',
      'UPST',
      'UVXY',
      'VALE',
      'VXX',
      'VZ',
      'WBD',
      'WFC',
      'XLE',
      'XLF',
      'XPEV',
      'AA',
      'AAOI',
      'AAP',
      'ABBV',
      'ABCM',
      'ACHR',
      'ADBE',
      'ADI',
      'AEM',
      'AG',
      'AGNC',
      'AGQ',
      'AKRO',
      'ALB',
      'ALGM',
      'ALLY',
      'ALTO',
      'AM',
      'AMAT',
      'AMPY',
      'AMSC',
      'AMT',
      'ANF',
      'APA',
      'APLS',
      'APP',
      'APT',
      'AR',
      'ARDX',
      'ARKG',
      'ARRY',
      'ASHR',
      'ASML',
      'ATVI',
      'AVGO',
      'AXTA',
      'AZN',
      'BBIO',
      'BBWI',
      'BBY',
      'BCRX',
      'BEKE',
      'BGS',
      'BHC',
      'BIDU',
      'BILI',
      'BITF',
      'BITO',
      'BKKT',
      'BKNG',
      'BLNK',
      'BLUE',
      'BMBL',
      'BMO',
      'BMY',
      'BNTX',
      'BOIL',
      'BP',
      'BURL',
      'BX',
      'BYND',
      'CAG',
      'CANO',
      'CARR',
      'CAT',
      'CAVA',
      'CGC',
      'CHGG',
      'CHS',
      'CHTR',
      'CHWY',
      'CMG',
      'COST',
      'COTY',
      'CPNG',
      'CPRT',
      'CRDO',
      'CRM',
      'CRON',
      'CRWD',
      'CS',
      'CVS',
      'CVX',
      'CWB',
      'D',
      'DASH',
      'DBI',
      'DDOG',
      'DFS',
      'DHC',
      'DIA',
      'DISH',
      'DLR',
      'DOCN',
      'DOCU',
      'DOW',
      'DPST',
      'DWAC',
      'EBAY',
      'EDR',
      'EGIO',
      'EGO',
      'EMB',
      'ENPH',
      'ENTG',
      'EPD',
      'EQNR',
      'ERX',
      'ERY',
      'ETRN',
      'ETSY',
      'EVGO',
      'EWC',
      'EXTR',
      'FANG',
      'FAS',
      'FAZ',
      'FCEL',
      'FFIE',
      'FL',
      'FLR',
      'FNGR',
      'FREY',
      'FSLR',
      'FSLY',
      'FSR',
      'FTNT',
      'FUBO',
      'FUTU',
      'GDRX',
      'GDXJ',
      'GEHC',
      'GES',
      'GGAL',
      'GIS',
      'GLNG',
      'GNRC',
      'GNS',
      'GOEV',
      'GOLD',
      'GPN',
      'GPS',
      'GRPN',
      'GS',
      'GSK',
      'GT',
      'HAL',
      'HCP',
      'HD',
      'HE',
      'HES',
      'HON',
      'HOOD',
      'HPE',
      'HPQ',
      'HSBC',
      'HZNP',
      'IAU',
      'IBKR',
      'IBM',
      'ICLN',
      'IEF',
      'INDI',
      'INVZ',
      'IOT',
      'IQ',
      'IRBT',
      'ITB',
      'IYR',
      'JBLU',
      'JDST',
      'JETS',
      'JNUG',
      'JOBY',
      'JWN',
      'KBE',
      'KEY',
      'KGC',
      'KHC',
      'KMI',
      'KO',
      'KSS',
      'LABD',
      'LABU',
      'LAZR',
      'LC',
      'LEN',
      'LI',
      'LIT',
      'LLY',
      'LMND',
      'LNC',
      'LNG',
      'LOW',
      'LQD',
      'LRCX',
      'LSCC',
      'LTHM',
      'LUV',
      'LVS',
      'LYFT',
      'LYV',
      'LZ',
      'M',
      'MANU',
      'MBLY',
      'MCD',
      'MCHP',
      'MDB',
      'MDT',
      'MELI',
      'MET',
      'MGM',
      'MO',
      'MP',
      'MPC',
      'MPLX',
      'MPW',
      'MQ',
      'MREO',
      'MRK',
      'MRNA',
      'MRO',
      'MS',
      'MSTR',
      'MULN',
      'NCHL',
      'NCLH',
      'NEE',
      'NEM',
      'NEP',
      'NFE',
      'NKE',
      'NTNX',
      'NU',
      'NUGT',
      'NVAX',
      'NXE',
      'NXPI',
      'OARK',
      'OKTA',
      'OPEN',
      'OSTK',
      'PAGS',
      'PARA',
      'PAYC',
      'PBF',
      'PD',
      'PENN',
      'PG',
      'PGY',
      'PINS',
      'PRU',
      'PSQ',
      'PTON',
      'QCOM',
      'QS',
      'RAD',
      'RCL',
      'REAL',
      'RETA',
      'RF',
      'RIG',
      'RILY',
      'RKLB',
      'RPRX',
      'RTX',
      'RUM',
      'RUN',
      'S',
      'SA',
      'SAVE',
      'SBLK',
      'SBUX',
      'SCHD',
      'SCO',
      'SDOW',
      'SEDG',
      'SGEN',
      'SH',
      'SILV',
      'SIRI',
      'SLB',
      'SMCI',
      'SMH',
      'SNBR',
      'SO',
      'SPLK',
      'SPOT',
      'SPR',
      'SPWH',
      'SPWR',
      'SPXL',
      'SPXS',
      'SPXU',
      'SQ',
      'SQM',
      'SRTY',
      'STLA',
      'STNE',
      'STNG',
      'SWKS',
      'SWN',
      'T',
      'TECK',
      'TECL',
      'TELL',
      'TEVA',
      'TEX',
      'TFC',
      'TMUS',
      'TNA',
      'TOL',
      'TSEM',
      'TSLL',
      'TSLY',
      'TSM',
      'TTD',
      'TTOO',
      'TWLO',
      'TZA',
      'U',
      'UAL',
      'UBS',
      'UCO',
      'UDN',
      'UDOW',
      'ULTA',
      'UNG',
      'UNH',
      'UNIT',
      'UPS',
      'URA',
      'URBN',
      'URTY',
      'USB',
      'UTZ',
      'UUP',
      'UVIX',
      'V',
      'VET',
      'VFC',
      'VIX',
      'VIXY',
      'VLD',
      'VLO',
      'VNO',
      'VOO',
      'VRM',
      'VRT',
      'W',
      'WBA',
      'WMB',
      'WMG',
      'WTRG',
      'WYNN',
      'X',
      'XBI',
      'XEL',
      'XHB',
      'XLB',
      'XLI',
      'XLK',
      'XLP',
      'XLU',
      'XLV',
      'XOP',
      'XRT',
      'XSP',
      'YINN',
      'ZI',
      'ZIM',
      'ZM',
      'ZS',
    ])]).map(s => ({ label: s, value: s }))
  },

  mapIndicators: (indicators, type) => {
    return indicators.map(i => {
      const { name } = i

      delete i.name
      delete i.indicator
      delete i.type
      
      for (const key of Object.keys(i.config || {})) {
        if (i.config[key] === '') {
          delete i.config[key]
        }
      }

      return {
        name,
        type,
        config: i.config || i,
      }
    })
  }
}
